import { SurveyCreatorComponent, SurveyCreator } from 'survey-creator-react'

import 'survey-core/survey.i18n.js'
import { surveyLocalization } from 'survey-core'
import { Navigate, useParams, Link as RouterLink } from 'react-router-dom'
import { Link } from '@mondra/ui-components'
import { useSurveyTemplates } from 'api/useSurveyTemplates'
import { SurveyParamsType, SurveyTypeEnum } from 'types'

const creatorOptions = {
  showLogicTab: true,
  showTranslationTab: true,
  isAutoSave: true,
  showThemeTab: true
}

//Limited the number of showing locales in survey.locale property editor
surveyLocalization.supportedLocales = ['en', 'de', 'fr', 'hu']

export function SurveyTemplate() {
  const { type, submissionType } = useParams<SurveyParamsType>()
  const { data: surveyTemplate, isLoading: isTemplateLoading } = useSurveyTemplates({
    type,
    submissionType,
  })
  const creator = new SurveyCreator(creatorOptions as any)
  creator.JSON = surveyTemplate
  creator.saveSurveyFunc = (saveNo: number | string) => {
    window.localStorage.setItem(
      `${type}-${submissionType}`,
      JSON.stringify(creator.JSON),
    )
  }
  if (!Object.values(SurveyTypeEnum).includes(type as SurveyTypeEnum)) {
    return <Navigate to="/error" />
  }

  return (
    <div className="flex-grow flex flex-col">
      <div className="bg-white flex-shrink-0 flex items-center justify-between mx-4 px-4 py-4 border-b mt-4 rounded-2xl shadow-sm">
        <div className="flex items-center space-x-2">
          <Link className="!text-primary-600" as={RouterLink} to="/templates">
            Survey Templates
          </Link>
          <div className="text-sm font-medium text-coolGray-800">/ {type} </div>
        </div>
      </div>
      <div className="flex-grow p-4 rounded-2xl">
        {isTemplateLoading ? (
          <div className="animate-pulse space-y-3">
            <div className="bg-gray-200 h-10 w-full" />
            <div className="bg-gray-200 h-10 w-full" />
            <div className="bg-gray-200 h-10 w-full" />
          </div>
        ) : (
          <SurveyCreatorComponent creator={creator} />
        )}
      </div>
    </div>
  )
}
