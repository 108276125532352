import { APP_NAV_URLS } from 'constants/appNavUrls'
import { UserContext } from 'contexts/UserContextProvider'
import { useContext } from 'react'
import { Navigate } from 'react-router-dom'

export function RequireAdmin({ children }) {
  const { isPermissionsLoading, is3KeelUser } = useContext(UserContext)
  if (isPermissionsLoading) {
    return null
  }
  return is3KeelUser ? children : <Navigate to={APP_NAV_URLS.SURVEYS} replace />
}

export function RedirectToAdmin({ children }) {
  const { isPermissionsLoading, is3KeelUser } = useContext(UserContext)
  if (isPermissionsLoading) {
    return null
  }
  return is3KeelUser ? <Navigate to={APP_NAV_URLS.ADMIN.BASE} /> : children
}
