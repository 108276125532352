import React from 'react'
import { Button, Notification } from '@mondra/ui-components'
import { useLocation, useSearchParams } from 'react-router-dom'
import { AUTH_ERRORS } from 'constants/authErrors'
import { COMPANY_ID_SEARCH_PARAM } from 'constants/'
import { useLoginRedirect } from 'hooks/useLoginRedirect'
import { AuthErrorTypesEnum } from 'types'
import noop from 'lodash/fp/noop'
import { APP_NAV_URLS } from 'constants/appNavUrls'

interface IErrorPageProps {
  deafaultErrorMsg?: string
}

export function ErrorPage({ deafaultErrorMsg }: IErrorPageProps) {
  const location = useLocation()
  const triggerLogin = useLoginRedirect()
  const { errorDescription } = location.state || {}
  const [searchParams] = useSearchParams()
  const companyId = searchParams.get(COMPANY_ID_SEARCH_PARAM)
  const errorKey = searchParams.get('errorKey')

  function handleTriggerLogin() {
    triggerLogin({
      companyId,
      defaultUrl: APP_NAV_URLS.SURVEYS
    })
  }

  const showLoginAction =
    errorKey === AuthErrorTypesEnum.no_token || errorKey === AuthErrorTypesEnum.login_required

  return (
    <div className="flex h-screen items-center justify-center">
      <div className="flex max-w-2xl flex-col">
        <div className="mb-4 text-[72px] font-medium text-coolGray-900">
          {errorKey ? 'Error' : 401} 😔
        </div>
        <div className="flex w-full justify-between pb-9">
          <div className="flex max-w-md flex-col justify-center border-r border-coolGray-200 pr-8">
            <p className="text-2xl font-medium text-coolGray-600">
              We can&rsquo;t seem to find the page you&rsquo;re looking for.
            </p>
            <p className="pt-4 text-sm font-normal leading-5 text-black">
              The page you are looking for may no longer be available, or an error may have
              occurred.
            </p>
          </div>
          <div className="ml-4 flex items-center">
            <a
              href={process.env.REACT_APP_CUSTOMER_SUPPORT_LINK}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button variant="primary" className="text-sm font-medium leading-4">
                Get Support
              </Button>
            </a>
          </div>
        </div>
        <Notification
          type="error"
          label={errorKey ? AUTH_ERRORS[errorKey] : 'Auth Error - 401'}
          actionTitle={showLoginAction ? 'Try Login again' : ''}
          onAction={showLoginAction ? handleTriggerLogin : noop}
        >
          {errorDescription || deafaultErrorMsg}
        </Notification>
      </div>
    </div>
  )
}
