import React, { useContext } from 'react'
import { Navigate } from 'react-router-dom'
import { APP_NAV_URLS } from 'constants/appNavUrls'
import { UserContext } from 'contexts/UserContextProvider'

export function UserNavigate() {
  const { isPermissionsLoading, is3KeelUser, isSurveyWindowClosed } = useContext(UserContext)
  if (isPermissionsLoading) return null
  if (!is3KeelUser && isSurveyWindowClosed) {
    return <Navigate to={APP_NAV_URLS.SURVEY_CLOSE_STATUS} />
  }
  return <Navigate to={is3KeelUser ? APP_NAV_URLS.ADMIN.BASE : APP_NAV_URLS.SURVEYS} />
}
