import { useMemo } from 'react'
import { API_URLS } from 'constants/apiUrls'
import isEmpty from 'lodash/fp/isEmpty'
import { REQUEST_ACCESS_DEFAULT_COMPANY } from 'constants/'
import { ICompany } from '../types/index'
import { useSWRWithToken } from './useSWRWithToken'

export function useCompanies(isFetchData: boolean) {
  const { data, error, isLoading } = useSWRWithToken<ICompany[]>(
    isFetchData ? API_URLS.COMPANIES : null,
  )

  const companies = useMemo(() => {
    return !isEmpty(data) && data
      ? data.filter((c: ICompany) => c.id !== REQUEST_ACCESS_DEFAULT_COMPANY)
      : []
  }, [data])

  return {
    data: companies,
    error,
    isLoading,
  }
}
