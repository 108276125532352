import React, { createContext,useState } from 'react'

const DEFAULT_LANGUAGE = 'English'


interface IInternalizationContext {
  language: string
  locale: string
  setLanguage: React.Dispatch<React.SetStateAction<string>>
}

const defaultContextProps = {
  language: DEFAULT_LANGUAGE,
  locale: 'en',
  setLanguage: () => {},
}

export const InternalizationContext = createContext<IInternalizationContext>(defaultContextProps)

interface IInternalizationProviderProps {
  children: React.ReactNode
}

export function InternalizationProvider({ children }: IInternalizationProviderProps) {
  const [language, setLanguage] = useState('English')
  return (
    <InternalizationContext.Provider
      value={{ language, setLanguage, locale: 'en' }}
    >
      {children}
    </InternalizationContext.Provider>
  )
}
