import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { ICompany } from 'types'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { COMPANY_ID_SEARCH_PARAM, EMPTY_COMPANY } from 'constants/'
import { APP_NAV_URLS } from 'constants/appNavUrls'
import { resolveUrl } from 'utils/resolveUrl'
import { useCompanies } from 'api/useCompanies'
import { isCompanyIdExist } from 'utils'
import { UserContext } from './UserContextProvider'

interface ICompanyContextProps {
  companies: ICompany[]
  company: ICompany
  error: Error | undefined
  isLoading: boolean
  setCompany: (company: any) => void
}

const defaultContextProps = {
  companies: [],
  company: EMPTY_COMPANY,
  error: undefined,
  isLoading: true,
  setCompany: () => {},
}

export const CompanyContext = createContext<ICompanyContextProps>(defaultContextProps)
interface ICompanyContextProviderProps {
  isAuthenticating: boolean
  children: React.ReactNode
}

export default function CompanyContextProvider({
  children,
  isAuthenticating,
}: ICompanyContextProviderProps) {
  const [company, setCompany] = useState<ICompany>(EMPTY_COMPANY)
  const [error, setError] = useState<Error>()
  const { userCompanyId, is3KeelUser, isPermissionsLoading, isSurveyWindowClosed } =
    useContext(UserContext)
  const { data: companies, isLoading } = useCompanies(
    !isPermissionsLoading && !is3KeelUser && !isSurveyWindowClosed,
  )
  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams()
  const appSearchCompanyId = searchParams.get(COMPANY_ID_SEARCH_PARAM)

  const setCompanyIdSearchParam = useCallback(
    (companyId: string) => {
      setSearchParams(params => {
        if (!params.get(COMPANY_ID_SEARCH_PARAM)) {
          params.set(COMPANY_ID_SEARCH_PARAM, companyId)
        }
        return params
      })
    },
    [setSearchParams],
  )

  const isSearchParamMissing = useMemo(() => {
    return !appSearchCompanyId && company.id
  }, [company.id, appSearchCompanyId])

  const isCompanyIdsMismatch = useMemo(() => {
    return appSearchCompanyId !== company.id
  }, [company.id, appSearchCompanyId])

  const companyId = useMemo(
    () => (isCompanyIdExist(appSearchCompanyId) ? appSearchCompanyId : userCompanyId),
    [appSearchCompanyId, userCompanyId],
  )

  useEffect(() => {
    setError(undefined)
  }, [companyId])

  useEffect(() => {
    if (isLoading || isPermissionsLoading || error || is3KeelUser) return

    if (isSurveyWindowClosed && !is3KeelUser) {
      navigate(resolveUrl(APP_NAV_URLS.SURVEY_CLOSE_STATUS, {}, {}))
      return
    }

    if (companies.length === 0) {
      setError(new Error('No companies data'))
      navigate(resolveUrl(APP_NAV_URLS.SURVEY_WELCOME, {}, { c: companyId }))
    }

    if (isSearchParamMissing) {
      setCompanyIdSearchParam(company.id)
      return
    }

    if (!companyId && companies[0]) {
      setCompany(companies[0])
      setCompanyIdSearchParam(companies[0].id)
    } else if (isCompanyIdsMismatch) {
      const newCompany = companies.find(c => c.id === companyId)
      if (newCompany) {
        setCompany(newCompany)
      } else {
        setError(new Error('Company does not exist'))
        navigate(resolveUrl(APP_NAV_URLS.SURVEY_WELCOME, {}, { c: companyId }))
      }
    }
  }, [
    companies,
    company.id,
    companyId,
    error,
    isCompanyIdsMismatch,
    isLoading,
    isSearchParamMissing,
    navigate,
    setCompanyIdSearchParam,
    is3KeelUser,
    isPermissionsLoading,
  ])

  const providerValue = useMemo(
    () => ({
      companies,
      company,
      error,
      isLoading: isLoading || isAuthenticating,
      setCompany,
    }),
    [companies, company, error, isAuthenticating, isLoading],
  )

  return <CompanyContext.Provider value={providerValue}>{children}</CompanyContext.Provider>
}
