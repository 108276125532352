import { AuthErrorTypesEnum } from 'types'

export const AUTH_ERRORS = {
  [AuthErrorTypesEnum.no_token]: 'Token not found',
  [AuthErrorTypesEnum.login_required]: 'Login required',
  [AuthErrorTypesEnum.consent_required]: 'Consent required',
  [AuthErrorTypesEnum.invalid_grant]: 'Invalid grant',
  [AuthErrorTypesEnum.unauthorized]: 'Unauthorized',
  [AuthErrorTypesEnum.timeout]: 'Timeout or Network error',
  [AuthErrorTypesEnum.invalid_request]: 'Invalid request',
  [AuthErrorTypesEnum.invalid_scope]: 'Invalid scope',
  [AuthErrorTypesEnum.server_error]: 'Internal server error',
  [AuthErrorTypesEnum.interaction_required]: 'Interaction required',
  [AuthErrorTypesEnum.access_denied]: 'Access denied',
}

export const NO_TOKEN = {
  key: 'no_token',
  description: 'Access token not found',
}
