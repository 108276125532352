export const API_URLS = {
  COMPANIES: `${process.env.REACT_APP_IDENTITY_API}/api/v1/Companies/GetMyCompanies`,
  COUNTRIES: `${process.env.REACT_APP_IDENTITY_API}/api/v1/countries`,
  ACCESS_REQUEST: `${process.env.REACT_APP_IDENTITY_API}/api/v1/accessrequests`,
  DECLARATION_UPLOAD: '/api/v1/company/:companyId/declarations/upload',
  DECLARATION_DOWNLOAD: '/api/v1/company/:companyId/declarations/download',
  DECLARATION_FILE_DELETE: '/api/v1/company/:companyId/declarations/delete',
  DECLARATIONS: '/api/v1/company/:companyId/declarations',
  DOWNLOAD_OFFLINE_TEMPLATE: '/api/v1/templates/offline/download',
  EXCLUDE_SURVEYS: '/api/v1/company/:companyId/surveys/request/exclude',
  FILE_DOWNLOAD: '/api/v1/company/:companyId/surveys/:surveyId/download',
  FILE_DELETE: '/api/v1/company/:companyId/surveys/:surveyId/delete',
  FILE_UPLOAD: '/api/v1/company/:companyId/surveys/:surveyId/upload',
  RETAILER_SUBMISSIONS: '/api/v1/company/:companyId/surveys/:type/retailer-submissions',
  SURVEYS: '/api/v1/company/:companyId/surveys',
  SURVEY_RESPONSE: '/api/v1/company/:companyId/surveys/:surveyId',
  SURVEY_SUBMIT: '/api/v1/company/:companyId/surveys/:surveyId/submit',
  SURVEY_TEMPLATE_OLD: '/api/v1/surveys/:submissionType/:type/template',
  SURVEY_TEMPLATE: '/api/v1/templates/:type/:submissionType/form',
  ADMIN: {
    SURVEYS: '/api/v1/admin/surveys/',
    SUPPLIERS: '/api/v1/admin/suppliers',
    RETAILERS: '/api/v1/admin/retailers',
    SURVEY: '/api/v1/admin/surveys/:surveyId',
    SURVEY_FILE_DOWNLOAD: '/api/v1/admin/surveys/:surveyId/download',
    DECLARATIONS: '/api/v1/admin/suppliers/:supplierId/declarations',
    DECLARATIONS_FILE_DOWNLOAD: '/api/v1/admin/suppliers/:supplierId/declarations/download',
    EXPORT: '/api/v1/admin/export',
    PROCESS_DECLARATIONS: '/api/admin/suppliers/:supplierId/declarations/process'
  },
}
