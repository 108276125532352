import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import isEmpty from 'lodash/fp/isEmpty'
import { BYPASS_SEARCH_PARAM, BYPASS_SEARCH_PARAM_VALUE } from 'constants/'
import { getByPassFlag, setByPassFlag } from 'utils'

export function useByPassClosure() {
  const [searchParams] = useSearchParams()

  const isByPass =
    !isEmpty(BYPASS_SEARCH_PARAM_VALUE) &&
    searchParams.get(BYPASS_SEARCH_PARAM) === BYPASS_SEARCH_PARAM_VALUE

  useEffect(() => {
    if (isByPass) {
      setByPassFlag()
    }
  }, [isByPass])

  return {
    isByPass: isByPass || getByPassFlag(),
  }
}
