import isEmpty from 'lodash/fp/isEmpty'
import { BYPASS_SESS_KEY, COMPANY_ID_SEARCH_PARAM } from 'constants/'

export function getCompanyId(): string | undefined {
  const urlParams = new URLSearchParams(window.location.search)

  return urlParams.get(COMPANY_ID_SEARCH_PARAM) || ''
}

export function isNullify(value?: string | number | null) {
  return value === '' || value === null || value === undefined
}

export function lowerCaseFirstWord(text: string) {
  if (typeof text !== 'string' || text.length === 0) {
    return text
  }

  return text.charAt(0).toLowerCase() + text.slice(1)
}

export function isCompanyIdExist(companyId?: string | null) {
  return !isEmpty(companyId) && companyId !== 'null' && companyId !== 'undefined'
}

export function setSessionStorage(key: string, value: string) {
  if (window.sessionStorage) {
    window.sessionStorage.setItem(key, value)
  }
}

export function getSessionStorage(key: string): string | null {
  if (window.sessionStorage) {
    return window.sessionStorage.getItem(key)
  }
  return null
}

export function setByPassFlag() {
  setSessionStorage(BYPASS_SESS_KEY, 'true')
}

export function getByPassFlag(): boolean {
  return getSessionStorage(BYPASS_SESS_KEY) === 'true'
}