// Mutate
export const ERRORS_TO_SKIP: (string | number)[] = []

export const DEFAULT_SERVER_ERROR_LABEL = 'Server Error:'
export const DEFAULT_SERVER_ERROR_MESSAGE = 'Something went wrong'

// Fetching
export const DEFAULT_FETCH_ERROR = 'An error occurred while fetching the data.'

export const ERROR_CODES = {
  BAD_REQUEST: 400
}
