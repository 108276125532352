import React from 'react'
import { Button, Card } from '@mondra/ui-components'
import { Link } from 'react-router-dom'
import { SurveyTypeEnum } from 'types'

export function SurveyTemplates() {
  return (
    <div className="p-4 flex-grow flex flex-col ">
      <div className="bg-white flex-shrink-0 flex items-center justify-between mx-4 px-4 py-4 border-b mt-4 rounded-2xl shadow-sm">
        <div className="text-sm font-medium text-coolGray-800">Survey Templates</div>
      </div>
      <div className="grid grid-cols-4 gap-2 p-4">
        {Object.values(SurveyTypeEnum).map(type => {
          return (
            <Card
              key={type}
              header={type}
              className="col-span-1 border"
              shadow="!shadow-xl rounded-xl shadow-primary-50"
            >
              <div className="flex items-center space-x-4 p-4">
                {type !== 'ContactConfirmation' && (
                  <Link to={`/templates/${type}/Offline`} className="w-full">
                    <Button variant="secondary" className="!w-full">
                      Offline
                    </Button>
                  </Link>
                )}
                <Link to={`/templates/${type}/Online`} className="w-full">
                  <Button className="!w-full">Online</Button>
                </Link>
              </div>
            </Card>
          )
        })}
      </div>
    </div>
  )
}
