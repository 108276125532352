/* eslint-disable */
import { useCallback } from 'react'
import isEmpty from 'lodash/fp/isEmpty'
import { useAuth0 } from '@auth0/auth0-react'
import { useNavigate } from 'react-router-dom'
import { APP_NAV_URLS } from 'constants/appNavUrls'
import { resolveUrl } from 'utils/resolveUrl'
import { NO_TOKEN } from 'constants/authErrors'
import { IAuthError } from 'api/types'
import { usePendoEvents } from './usePendEvents'
import { useRetryAsync } from './useRetryAsync'

const SCOPE = 'user profile email'
const MAX_RETRIES = 2

export function useToken() {
  const { getAccessTokenSilently, user } = useAuth0()
  const { trackEvent } = usePendoEvents()
  const navigate = useNavigate()
  const acquireTokenWithRetry = useRetryAsync(getAccessTokenSilently, MAX_RETRIES)

  const acquireToken = useCallback(
    async (companyId?: string) => {
      if (process.env.REACT_APP_ENABLE_MOCK) {
        return Promise.resolve('')
      }

      const scope = isEmpty(companyId) ? SCOPE : SCOPE.concat(' ', `c_${companyId}`)

      try {
        const token = await acquireTokenWithRetry({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope,
        })
        if (token) {
          return token
        }
      } catch (error) {
        const typedError = error as IAuthError
        trackEvent(typedError?.error, {
          errorDescription: typedError?.error_description,
        })
        // On the last attempt If an error occurs, navigate to error page
        navigate(resolveUrl(APP_NAV_URLS.ERROR, {}, { errorKey: typedError?.error }), {
          state: {
            errorKey: typedError?.error,
            errorDescription: typedError?.error_description || 'NA',
          },
        })
      }
      trackEvent(NO_TOKEN.key, {
        errorDescription: NO_TOKEN.description,
      })
      // If no token is obtained after retries, navigate to error page
      navigate(resolveUrl(APP_NAV_URLS.ERROR, {}, { errorKey: NO_TOKEN.key }), {
        state: { errorKey: NO_TOKEN.key, errorDescription: NO_TOKEN.description },
      })
    },
    [getAccessTokenSilently],
  )

  return {
    acquireToken,
    user,
  }
}
