/**
 * First add key to .env or release variables
 * Then based on the availability of the falg,
 * a particular feature or UI can be shown or not in the UI
 * If there is no key populated in the Release variable or .env file
 * then by default that feature is shown
 */
export const FEATURE_FLAG_KEYS = {
  SURVEYS: 'surveys',
  SURVEYS_CLOSE: 'surveysCloseStatus'
}
