import React from 'react'
import ReactDOM from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'

import { LicenseManager } from 'ag-grid-enterprise'
import { Toaster } from '@mondra/ui-components'
import 'survey-creator-core/survey-creator-core.min.css'
import 'survey-core/modern.css'
import { InternalizationProvider } from 'contexts/InternalizationProvider'
import { browserRouter } from 'Routes'
import './index.css'
import 'survey-core/defaultV2.min.css'
import './i18n'
import { toastOptions } from 'constants/'

LicenseManager.setLicenseKey(`${process.env.REACT_APP_AG_GRID_LICENSE}`)

// Start the mocking conditionally.
if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_ENABLE_MOCK) {
  // eslint-disable-next-line
  const { worker } = require('./mocks/browser')
  worker.start()
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <InternalizationProvider>
      <RouterProvider router={browserRouter} />
      <Toaster containerClassName="!z-[10000]" toastOptions={toastOptions}/>
    </InternalizationProvider>
  </React.StrictMode>,
)
