import React from 'react'
import { Icon, MenuPopover } from '@mondra/ui-components'
import { useTranslation } from 'react-i18next'

const languageLabels: Record<string, string> = {
  en: 'English',
  de: 'Deutsch',
  fr: 'Français',
  hu: 'Magyar',
}

//TODO: We need to remove the onClick method for each item
export function LanguagesMenu() {
  const { i18n } = useTranslation()

  const onClick = (inLng: string) => () => {
    i18n.changeLanguage(inLng)
  }

  const menuItems = Object.keys(languageLabels).map((lng: string) => ({
    id: lng,
    onClick: onClick(lng),
    value: languageLabels[lng],
  }))

  return (
    <div className="text-sm font-medium">
      <MenuPopover
        triggerButtonClass='group !border-r !border-r-coolGray-200 !border-b-0 h-14 max-h-14'
        menuItems={menuItems}
        trigger={
          <div className="flex items-center gap-x-2 text-primary-600 group-hover:text-primary-900">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.5 21l5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 016-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 01-3.827-5.802"
              />
            </svg>
            {languageLabels[i18n.language]}
            <Icon type="chevronDown" size={20} className="text-current" />
          </div>
        }
      />
    </div>
  )
}
