import React, { Suspense, lazy } from 'react'
import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom'
import { Auth0ProviderWithHistory } from 'auth/Authprovider'
import { SurveyTemplates, SurveyTemplate } from 'pages/templates'
import { APP_NAV_URLS } from 'constants/appNavUrls'
import { ErrorPage } from 'pages/error-page/ErrorPage'
import { LogoLoader } from 'components/skeleton-loaders/LogoLoader'
import { FEATURE_FLAG_KEYS } from 'constants/featureFlagKeys'
import { isFeatureEnable } from 'utils/isFeatureEnabled'
import App from 'App'
import { RedirectToAdmin, RequireAdmin } from 'components/RequireAdmin'
import { UserNavigate } from 'components/UserNavigate'

const Surveys = lazy(() => import('./pages/surveys/Surveys'))
const AllSurveys = lazy(() => import('./pages/admin/AllSurveys'))

const Survey = lazy(() => import('./pages/surveys/Survey'))
const SurveyOpenStatus = lazy(() => import('./pages/surveys-open-status/SurveyOpenStatus'))
const SurveyWelcome = lazy(() => import('./pages/surveys-open-status/SurveyWelcome'))
const ContactConfirmation = lazy(() => import('./pages/contact-confirmation/ContactConfirmation'))
const OfflineDeclaration = lazy(() => import('pages/offline-declaration/OfflineDeclaration'))
const SurveyCloseStatus = lazy(() => import('pages/surveys-open-status/SurveyCloseStatus'))

export const browserRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="/"
      element={
        <Auth0ProviderWithHistory>
          <App />
        </Auth0ProviderWithHistory>
      }
    >
      <Route index element={<UserNavigate />} />
      {isFeatureEnable(FEATURE_FLAG_KEYS.SURVEYS) && (
        <Route
          path={APP_NAV_URLS.SURVEYS}
          element={
            <Suspense fallback={<LogoLoader />}>
              <RedirectToAdmin>
                <Surveys />
              </RedirectToAdmin>
            </Suspense>
          }
        />
      )}
      <Route
        path={APP_NAV_URLS.SURVEY}
        element={
          <Suspense fallback={<LogoLoader />}>
            {isFeatureEnable(FEATURE_FLAG_KEYS.SURVEYS) ? <Survey /> : <SurveyOpenStatus />}
          </Suspense>
        }
      />
      <Route
        path={APP_NAV_URLS.OFFLINE_DECLARATION}
        element={
          <Suspense fallback={<LogoLoader />}>
            <OfflineDeclaration />
          </Suspense>
        }
      />

      <Route
        path={APP_NAV_URLS.SURVEY_OPEN_STATUS}
        element={
          <Suspense fallback={<LogoLoader />}>
            <RedirectToAdmin>
              <SurveyOpenStatus />
            </RedirectToAdmin>
          </Suspense>
        }
      />
      <Route
        path={APP_NAV_URLS.SURVEY_CLOSE_STATUS}
        element={
          <Suspense fallback={<LogoLoader />}>
            <SurveyCloseStatus />
          </Suspense>
        }
      />
      <Route
        path={APP_NAV_URLS.SURVEY_WELCOME}
        element={
          <Suspense fallback={<LogoLoader />}>
            <RedirectToAdmin>
              <SurveyWelcome />
            </RedirectToAdmin>
          </Suspense>
        }
      />
      <Route
        path={APP_NAV_URLS.CONTACT_CONFIRMATION}
        element={
          <Suspense fallback={<LogoLoader />}>
            <RedirectToAdmin>
              <ContactConfirmation />
            </RedirectToAdmin>
          </Suspense>
        }
      />
      <Route path={APP_NAV_URLS.ERROR} element={<ErrorPage />} />
      <Route
        path={APP_NAV_URLS.ADMIN.BASE}
        element={
          <Suspense fallback={<LogoLoader />}>
            <RequireAdmin>
              <AllSurveys />
            </RequireAdmin>
          </Suspense>
        }
      />
      <Route
        path={APP_NAV_URLS.ADMIN.TEMPLATES}
        element={
          <RequireAdmin>
            <SurveyTemplates />
          </RequireAdmin>
        }
      />
      <Route
        path={APP_NAV_URLS.ADMIN.TEMPLATE}
        element={
          <RequireAdmin>
            <SurveyTemplate />
          </RequireAdmin>
        }
      />
      <Route
        path="*"
        element={<ErrorPage deafaultErrorMsg="Sorry! Cannot find the page you are looking for" />}
      />
    </Route>,
  ),
)
