import queryString from 'query-string'

export function sanatizeParams(queryStringIn: string) {
  const queryParams = queryString.parse(queryStringIn)
  // queryString.stringify is not removing 'null' values, so below the is the work around
  // eslint-disable-next-line
  for (const param in queryParams) {
    if (queryParams[param] === 'null') {
      delete queryParams[param]
    }
  }
  return queryString.stringify(queryParams, {
    skipEmptyString: true,
    skipNull: true,
  })
}

export function buildQueryString(queryParams) {
  return queryString.stringify(queryParams, {
    skipEmptyString: true,
    skipNull: true,
  })
}
