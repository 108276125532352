import { INotificationProps } from '@mondra/ui-components/dist/components/nofitication/Notification'
import { DEFAULT_FETCH_ERROR } from './constants'

interface IErrorData extends ErrorConstructor {
  info: Response
  status: number
}

interface IFetchWithTokenProps {
  apiUrl: string
  companyId?: string
  onError?: (params: INotificationProps) => void
  acquireToken: (companyId?: string) => Promise<any>
}

export const fetchWithToken = async ({
  apiUrl,
  companyId,
  acquireToken,
  onError,
}: IFetchWithTokenProps) => {
  const token = await acquireToken(companyId)
  const options = process.env.REACT_APP_ENABLE_MOCK
    ? {}
    : {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          'X-MONDRA-APP': 'Commodities',
        },
      }
  const res = await fetch(apiUrl, options)

  if (!res.ok) {
    const info = await res.json()

    if (onError) {
      onError({
        description: DEFAULT_FETCH_ERROR,
        label: 'Error:',
        type: 'error',
      })
    }

    // eslint-disable-next-line
    throw {
      ...(new Error(DEFAULT_FETCH_ERROR) as Partial<IErrorData>),
      info,
      status: res.status,
    }
  }

  return res.json()
}
