/* eslint-disable @typescript-eslint/no-explicit-any */
import { generatePath, Params } from 'react-router-dom'
import getOr from 'lodash/fp/getOr'
import some from 'lodash/fp/some'
import isObject from 'lodash/fp/isObject'
import isArray from 'lodash/fp/isArray'
import { isNullify } from 'utils'

type QueryParamType = string | string[][] | Record<string, any>

type NullifyParamType = QueryParamType | Params
export function checkIsNullify(params?: NullifyParamType) {
  if (isObject(params) && some(isNullify, Object.values(params))) {
    return true
  }
  if (isArray(params) && some((value: string[]) => isNullify(value[1]), params)) {
    return true
  }
  return false
}

export function resolveApiUrl(path: string, params?: Params, queryParams?: QueryParamType): string {
  const apiPath = `${process.env.REACT_APP_API_URL}${generatePath(path, params)}`
  if (queryParams && !checkIsNullify(queryParams)) {
    return `${apiPath}?${new URLSearchParams(queryParams).toString()}`
  }
  return apiPath
}

export function resolveSwrUrl(
  path: string,
  params?: Params,
  queryParams?: QueryParamType,
): string | null {
  if (!path || checkIsNullify(params)) {
    return null
  }
  return resolveApiUrl(path, params, queryParams)
}

export function resolveUrl(path: string, params?: Params, queryParams?: QueryParamType): string {
  const url = generatePath(path, params)
  if (queryParams) {
    return `${url}?${new URLSearchParams(queryParams).toString()}`
  }
  return url
}

/**
 * generatePath from react-router won't support full URL with http(s)://
 * @param path path can be full url: https://xyz.com/:param1/:param2
 * @param params key-value pair with params: { param1: "value1", param2: "value2" }
 * @returns resolved url
 */
export function resolveExternalUrl(path: string, params: Params): string {
  return Object.keys(params).reduce((url, key) => {
    const value = getOr('', key, params)
    return url.replace(new RegExp(`:${key}`, 'gi'), value)
  }, path)
}
