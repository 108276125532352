import React, { Component, ReactNode } from 'react'
import { SUPPORT_EMAIL } from 'constants/'
import { Notification } from '@mondra/ui-components'

interface ErrorBoundaryState {
  hasError: boolean
  error?: Error
}
interface ErrorBoundaryProps {
  children: ReactNode
}

export default class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
    }
  }

  static getDerivedStateFromError(error: Error) {
    return {
      hasError: true,
      error,
    }
  }

  render() {
    if (this.state.hasError && this.state.error) {
      return (
        <div className="p-4 flex items-center justify-center flex-grow">
          <div className="flex flex-col items-center flex-grow justify-center bg-white p-11 rounded  mx-auto drop-shadow-2xl  max-w-4xl">
            <h5 className="text-xl leading-8 font-semibold flex-shrink-0 tracking-tight text-primary-600">
              Something went wrong. Please contact us:
            </h5>
            <Notification type="error" description={this.state.error.message}>
              <div className="text-red-600 max-h-[450px] overflow-y-auto">
                <div className='italic'>{this.state.error.stack}</div>
              </div>
            </Notification>
            <div>
              <a
                className="text-primary-600"
                href={`mailto:${SUPPORT_EMAIL}`}
                title="contact 3Keel mondra support"
              >
                {SUPPORT_EMAIL}
              </a>
            </div>
          </div>
        </div>
      )
    }

    return this.props.children
  }
}
