import { useMemo } from 'react'
import isEmpty from 'lodash/fp/isEmpty'
import { SubmissionTypeEnum, SurveyTypeEnum } from 'types'
import { API_URLS } from '../constants/apiUrls'
import { resolveSwrUrl } from '../utils/resolveUrl'
import { useSWRWithToken } from './useSWRWithToken'

interface ISurveyResponseProps {
  submissionType?: SubmissionTypeEnum
  type?: SurveyTypeEnum
}

export function useSurveyTemplates({ submissionType, type }: ISurveyResponseProps) {
  const url = useMemo(
    () =>
      submissionType && type
        ? resolveSwrUrl(API_URLS.SURVEY_TEMPLATE, {
            submissionType,
            type,
          })
        : null,
    [submissionType, type],
  )

  const { data, error, isLoading } = useSWRWithToken(url)
  return {
    data: data,
    error,
    isLoading: isLoading || isEmpty(url),
  }
}
